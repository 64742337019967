// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-four-0-four-js": () => import("./../src/pages/four0four.js" /* webpackChunkName: "component---src-pages-four-0-four-js" */),
  "component---src-pages-sandbox-01-js": () => import("./../src/pages/sandbox-01.js" /* webpackChunkName: "component---src-pages-sandbox-01-js" */),
  "component---src-pages-sandbox-02-js": () => import("./../src/pages/sandbox-02.js" /* webpackChunkName: "component---src-pages-sandbox-02-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-js": () => import("./../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-templates-topic-js": () => import("./../src/templates/topic.js" /* webpackChunkName: "component---src-templates-topic-js" */)
}

